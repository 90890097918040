import React from 'react';
import DialogWindow from './DialogWindow';
import {useUserContext} from "../../context/userConext";
import {Permissions} from "../../types/authTypes";
import CreatePrinterForm from "./printerForm/CreatePrinterForm";


function OperationBlock() {
    const userInfo = useUserContext();
    const nameCommandMapper = {
        'Install & Configure': 'install',
        'Uninstall': 'uninstall',
        'Configure': 'configure'
    }

    return (
        <>
            {(userInfo.permissions === Permissions.global_admin_permission ||
               userInfo.permissions === Permissions.local_admin_permission) ?
                <CreatePrinterForm
                    name={"Add printer"}
                    url={'/embedded_clients'}
                ></CreatePrinterForm>: null}
            {userInfo.permissions === Permissions.global_admin_permission ?
                <><DialogWindow
                    name={"Embedded Client setup"}
                    url={'/embedded_clients'}
                    operation={nameCommandMapper}
                    exampleFile={'embedded_client_example.csv'}
                    exampleFileType={'CSV'}
                ></DialogWindow><DialogWindow
                    name={"Printer setup"}
                    url={'/outputports'}
                    exampleFile={'printers_example.csv'}
                    exampleFileType={'CSV'}
                ></DialogWindow><DialogWindow
                    name={"Containers setup"}
                    url={'/container'}
                    exampleFile={'container_structure_example.json'}
                    exampleFileType={'JSON'}
                ></DialogWindow
                ></> : null}
        </>
    )
}


export default OperationBlock;