import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import {printerDataType} from "../../types/printerTypes";
import TablePlaceholder from '../placeholders/TablePlaceholder';
import {PrinterDataContext, usePrinterDataContext} from "../../context/printerDataContext";
import ExportPrintersButton from "./ExportPrintersButton";
import PrinterSearch from "./PrinterSearch";
import ActionButtons from "./PrinterTableActionButtons";
import Loading from "./Loading";
import FilterMenu, {defaultFilterState} from "./FilterMenu";


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof printerDataType;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'IP Address',
  },
  {
    id: 'deviceSerial',
    numeric: false,
    disablePadding: false,
    label: 'Serial',
  },
  {
    id: 'building_name',
    numeric: false,
    disablePadding: false,
    label: 'Building',
  },
  {
    id: 'city_name',
    numeric: false,
    disablePadding: false,
    label: 'City',
  },
  {
    id: 'country_name',
    numeric: false,
    disablePadding: false,
    label: 'Country',
  },
  {
    id: 'region_name',
    numeric: false,
    disablePadding: false,
    label: 'Region',
  },
  {
    id: 'document_output',
    numeric: false,
    disablePadding: false,
    label: 'Document Output',
  },
  {
    id: 'terminal_client',
    numeric: false,
    disablePadding: false,
    label: 'Terminal Client',
  },
  {
    id: 'embeddedConfigurationId',
    numeric: false,
    disablePadding: false,
    label: 'Reader',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'last_updated',
    numeric: false,
    disablePadding: false,
    label: 'Updated on',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof printerDataType) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof printerDataType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  selected: printerDataType[] | undefined;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, selected} = props;
  const [filterInput, setFilterInput] = React.useState(defaultFilterState);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Printers
        </Typography>
      )}
      {numSelected > 0 ? (
          <ActionButtons
              selected={selected}
          />
      ) : (
          <>
            <PrinterSearch/>
            <ExportPrintersButton/>
            <FilterMenu filterState={filterInput} setFilterState={setFilterInput}/>
          </>
      )}
    </Toolbar>
  );
}
export default function EnhancedTable() {
  const {allPrinters, printers, setPrinters, isLoading} = usePrinterDataContext();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof printerDataType>('name');
  const [selected, setSelected] = React.useState<printerDataType[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof printerDataType,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = printers.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: printerDataType) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: printerDataType[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: printerDataType) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - printers.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(printers, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, printers],
  );

  const readerLabelMapping: { [key: number]: string } = {
    3982: 'classic',
    4632: 'mobile',
    2717: 'classic',
    3914: 'mobile',
  };

  const column_labels = headCells.map(item => item.label);

  React.useEffect(() => {
    setSelected([]);
  }, [printers]);

  return (
    <React.Fragment>
    <Loading/>
    <Box sx={{ width: '100%', margin: '5pt'}}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
        />
        {isLoading ? <Box style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}>
              <TablePlaceholder rows={10} columns={column_labels} />
            </Box> :
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={printers.length}
            />
            <TableBody>

              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="normal"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell >{row.address}</TableCell>
                    <TableCell>{row.deviceSerial}</TableCell>
                    <TableCell>{row.building_name}</TableCell>
                    <TableCell>{row.city_name}</TableCell>
                    <TableCell>{row.country_name}</TableCell>
                    <TableCell>{row.region_name}</TableCell>
                    <TableCell>{row.document_output}</TableCell>
                    <TableCell>{row.terminal_client}</TableCell>
                    <TableCell>{readerLabelMapping[row.embeddedConfigurationId] || row.embeddedConfigurationId}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.last_updated}</TableCell>
                    <TableCell >{row.id}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        }
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, { value: printers.length, label: 'All' }]}
          component="div"
          count={printers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
      </React.Fragment>
  );
}
