import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StickyHeadTable from "./modules/ResultTable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function GeneralDialogPopUp(props:
{
    tableData: any;
    dataType: string;
    openState: boolean;
    setOpen: any;
    setShouldReload: any;
}) {
  const tableData = props.tableData;
  const dataType = props.dataType;
  const open = props.openState;
  const setOpen = props.setOpen;
  const setShouldReload = props.setShouldReload

  const handleClose = () => {
    setOpen(false);
    // setShouldReload(true);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Embedded status
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <StickyHeadTable dataType={dataType} tableData={tableData}/>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}