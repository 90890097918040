import {useEffect, useState, Fragment} from "react";
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Alert, Box, Fade, Menu, MenuItem, Snackbar} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TextField from '@mui/material/TextField';
import {usePrinterDataContext} from "../../../context/printerDataContext";
import CopyToClipboardButton from "../CopyToClipboardButton";
import s from "./PrinterForm.module.css"
import {utils_instance} from "../../../utils/Utils";
import {ApiInstance} from "../../../api/api";
import {outputPortCreationResponseDataType, printerDataType} from "../../../types/printerTypes";
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';


const editButtonStyle = {
  width: '190pt',
  margin: '5pt',
  height: '30pt',
  whiteSpace: 'nowrap',
  padding: '15px 5px',
};


const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
}));


const PrinterFormDropdown = (props: {
    set_option: string | null;
    options: Array<string | null>;
    setFilter: any;
    disabled: Boolean;
    error?: Boolean;
}) => {
    const set_option = props.set_option;
    const options = props.options;
    const setFilter = props.setFilter;
    const disabled = props.disabled;
    const error = props.error;

    const [selectedValue, setSelectedValue] = useState<string | null>(
        set_option != null ? set_option : options[0]
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleOption = (event: any) => {
        setSelectedValue(event.target.textContent);
        setFilter(event.target.textContent)
        setAnchorEl(null);
    };

    useEffect(() =>{
        if(set_option == null){
            setSelectedValue('choose new')
        }
    }, [set_option])


    return (
        <Fragment>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                endIcon={<KeyboardArrowDownIcon/>}
                onClick={handleClick}
                disabled={disabled ? true : false}
                color={error ? 'error' : 'primary'}
            >
                {selectedValue}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{'aria-labelledby': 'fade-button'}}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
            >
                {options.map(key2 => <MenuItem onClick={handleOption}>{key2}</MenuItem>)}
            </Menu>
        </Fragment>
    )
};


export default function UpdatePrinterForm(props:
{
    name: string;
    selected_entity: printerDataType[];
}) {
    const {allPrinters} = usePrinterDataContext();
    const operationName = props.name;
    const selected_entity = props.selected_entity;

    const buildingRegion = selected_entity[0].region_name;
    const buildingCountry = selected_entity[0].country_name;
    const buildingCity = selected_entity[0].city_name;
    const buildingName = selected_entity[0].building_name;

    const printerId = selected_entity[0].id;
    const printerIp = selected_entity[0].address;
    const printerSerial = selected_entity[0].deviceSerial;
    const printerInitialName = selected_entity[0].name;

    const [open, setOpen] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [apiResponse, setApiResponse] = useState<outputPortCreationResponseDataType | null>(null);

    const [city, setCity] = useState<string | null>(null)
    const [building, setBuilding] = useState<string | null>(null)

    const [cities, setCities] = useState<Array<string | null>>(['chose one'])
    const [buildings, setBuildings] = useState<Array<string | null>>(['chose one'])

    const [ipAddress, setIpAddress] = useState<string | null>(null);
    const [detail, setDetail] = useState<string | null>(null);
    const [serial, setSerial] = useState<string | null>(null);
    const [printerName, setPrinterName] = useState<string>('BUILD_SERIAL--IP__DETAIL');

    const [ipError, setIpError] = useState(false);
    const [buildingError, setBuildingError] = useState(false);
    const [error, setError] = useState(true);

    useEffect(() =>{
        if (buildingCountry){
            const uniqueCities = Array.from(
              new Set(allPrinters
                  .filter(item => item.region_name === buildingRegion)
                  .filter(item => item.country_name === buildingCountry)
                  .map(item => item.city_name))
            ).filter(name => name !== 'nan');

            setCities(uniqueCities)
            setCity(null)
            setBuilding(null)
        }

    }, [buildingCountry])

    useEffect(() =>{
        if (city){
            const uniqueBuildings = Array.from(
              new Set(allPrinters
                  .filter(item => item.region_name === buildingRegion)
                  .filter(item => item.country_name === buildingCountry)
                  .filter(item => item.city_name === city)
                  .map(item => item.building_name))
            ).filter(name => name !== 'nan');

            setBuildings(uniqueBuildings)
            setBuilding(null)
        }
    }, [city])

    useEffect(() =>{
        if (selected_entity) {
        let b_name =  building ? building : buildingName
        let b_serial =  printerSerial ? printerSerial : 'SERIAL'
        let b_ip =  ipAddress ? ipAddress : printerIp
        let b_detail =  detail ? detail : printerInitialName.split("__")[1]

        let name = b_name + '_' + b_serial + '--' + b_ip + '__' + b_detail
        setPrinterName(name);

        setError(false)
        if (ipAddress == null || !utils_instance.isIPAddressValid(ipAddress)){setError(true)}
        if (detail == null || detail === ''){setError(true)}
        if (building ==null || building === ''){setError(true)}

        setBuildingError(false)
        if (building == null || building === ''){setBuildingError(true)}
        }

    }, [building, ipAddress, serial, detail])

    useEffect(() =>{
        if (apiResponse != null && apiResponse["status"]){
            setPrinterName(apiResponse["printer_name"])
        }
    }, [apiResponse])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setApiResponse(null);

        setIpAddress(null);
        setDetail(null);
        setSerial(null);

        setCity(null);
        setBuilding(null);
    };

    const handleSubmitEvent = async (e: any) => {
        const selectedBuilding = building ? building : buildingName;
        const updateContainer = building !== buildingName ? true : false;

        const res = await ApiInstance.updateOutputPort(printerId, ipAddress, printerSerial, detail, selectedBuilding, updateContainer);

        if (res['status']){
            let updated_printer = printerName;

            setIpAddress(null);
            setDetail(null);
            setSerial(null);

            setPrinterName(updated_printer);
        }
        setApiResponse(res);
        setOpenMessage(true);
    }


    return (
        <Fragment>
            <Button
                variant='outlined'
                sx={{ ...editButtonStyle }}
                onClick={handleClickOpen}
            >
                {operationName}
            </Button>
            { selected_entity?.length !== 1 ? (
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle>
                    <Box>
                        {operationName}
                    </Box>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Box className={s.form_box_item}>
                        <Box className={s.box_device_name}>
                            <BlockRoundedIcon fontSize="large" color="error" />
                            <Typography component={'div'} textAlign="center">
                                Action is not available for multiple printers. Please select only one printer!
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={s.form_print_button}
                        variant='outlined'
                        onClick={handleClose}
                    >Close</Button>
                </DialogActions>
            </BootstrapDialog>
            ) : (
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    {operationName} with ID: {printerId}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Box className={s.form_item}>
                        <Box className={s.box_item}>
                            <p>Region:</p>
                            <Typography
                                className={s.form_data_item}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                {buildingRegion}
                            </Typography>
                        </Box>
                        <Box className={s.box_item}>
                            <p>Country:</p>
                            <Typography
                                className={s.form_data_item}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                {buildingCountry}
                            </Typography>
                        </Box>
                        <Box className={s.box_item}>
                            <p>City:</p>
                            <PrinterFormDropdown
                                options={cities}
                                setFilter={setCity}
                                set_option={buildingCity}
                                disabled={false}
                            />
                        </Box>
                        <Box className={s.box_item}>
                            <p>Building:</p>
                            <PrinterFormDropdown
                                options={buildings}
                                setFilter={setBuilding}
                                set_option={buildingName}
                                disabled={city == null}
                                error={buildingError}
                            />
                        </Box>
                        <Box className={s.box_item}>
                            <p>Serial:</p>
                            <Typography
                                className={s.form_data_item}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                {printerSerial}
                            </Typography>
                        </Box>
                        <Box className={s.box_item}>
                            <p>IP address*:</p>
                            <TextField
                                id="outlined-basic"
                                placeholder="IP Address"
                                defaultValue={printerIp}
                                variant="outlined" size="small"
                                error={ipError}
                                onChange={(e) => {
                                    setIpAddress(e.target.value);
                                    setIpError(!utils_instance.isIPAddressValid(e.target.value));
                                }}
                            />
                        </Box>
                        <Box className={s.box_item}>
                            <p>Detail*:</p>
                            <TextField
                                id="outlined-basic"
                                placeholder="Detail"
                                defaultValue={printerInitialName.split("__")[1]}
                                variant="outlined" size="small"
                                onChange={(e) => setDetail(e.target.value)}
                            />
                        </Box>
                        <Box className={s.box_device_name}>
                            {printerName}
                            <CopyToClipboardButton text={printerName}/>
                        </Box>
                        {apiResponse == null ? null :
                            apiResponse['status'] ?
                                <Fragment>
                                    <Snackbar
                                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                        autoHideDuration={10000}
                                        onClose={() => setOpenMessage(false)}
                                        open={openMessage}
                                    >
                                        <Alert icon={false} severity="success" id={s.success_alert_popup}>
                                            <div className={s.alert_content}>
                                                <div className={s.alert_icon_section}>
                                                    <CheckCircleOutlineIcon fontSize="large" className={s.alert_icon} />
                                                </div>
                                                <div>
                                                    <Typography>Printer updated successfully! To see the changes, please reload the page.</Typography>
                                                    <Typography variant="body2">Please make sure that you <b>Install & Configure</b> printer before using it!</Typography>
                                                </div>
                                            </div>
                                        </Alert>
                                    </Snackbar>
                                </Fragment>
                                :
                                <Fragment>
                                    <Alert variant="outlined" severity="error">
                                      Error occurred
                                    </Alert>
                                    <Snackbar
                                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                        autoHideDuration={10000}
                                        onClose={() => setOpenMessage(false)}
                                        open={openMessage}
                                    >
                                        <Alert icon={false} severity="error" id={s.error_alert_popup}>
                                            <div className={s.alert_content}>
                                                <div className={s.alert_icon_section}>
                                                    <ErrorOutlineIcon fontSize="large" className={s.alert_icon} />
                                                </div>
                                                <div>
                                                    <Typography>Printer not updated!</Typography>
                                                    <Typography variant="body2">Error occurred - please try again!</Typography>
                                                </div>
                                            </div>
                                        </Alert>
                                    </Snackbar>
                                </Fragment>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={s.form_print_button}
                        component="label"
                        variant="contained"
                        onClick={handleSubmitEvent}
                        disabled={error}
                    >
                        Update
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            )}
        </Fragment>
    );
}
