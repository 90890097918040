import React, {useEffect, useState} from 'react';
import Header from "../modules/Header";
import OperationBlock from '../modules/OperationBlock';
import Message from "../modules/Message";
import {Box} from "@mui/material";
import EnhancedTable from "../modules/PrinterTable";
import {Permissions} from "../../types/authTypes";
import {useUserContext} from "../../context/userConext";
import {ApiInstance} from "../../api/api";
import {printerDataType} from "../../types/printerTypes";
import { PrinterDataContext } from '../../context/printerDataContext';

function MainPage() {
    const userInfo = useUserContext();

    const [isLoading, setIsLoading] = useState(false);
    const [allPrinters, setAllPrinters] = useState<printerDataType[]>([]);
    const [printers, setPrinters] = useState<printerDataType[]>([]);
    const [loadingState, setLoadingState] = useState(false);
    const [shouldReload, setShouldReload] = useState(true);

    const getPrinters = async () => {
        setIsLoading(true);
        const printerData = await ApiInstance.getPrinters();

        setAllPrinters(printerData.output_ports);
        setPrinters(printerData.output_ports);
        setIsLoading(false);
        setShouldReload(false);
    }

  useEffect(() => {
    if (shouldReload) {
      getPrinters();
    }
  }, [shouldReload]);

    return (
        <PrinterDataContext.Provider value={{
            allPrinters, printers, loadingState, setPrinters, setLoadingState, isLoading, shouldReload, setShouldReload
        }}>
            <Header/>
            {
                userInfo.permissions !== Permissions.no_permission ?
                    <Box sx={{margin: 'auto', width: '95%'}}>
                        <OperationBlock/>
                        <EnhancedTable/>
                    </Box>
                :
                    <Message message='Oooops... Not enough permissions to view this content.'/>
            }
        </PrinterDataContext.Provider>
    )
}


export default MainPage;