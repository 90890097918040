import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import {usePrinterDataContext} from "../../context/printerDataContext";
import TextField from "@mui/material/TextField";


const menuStyle = {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    },
}

const buttonStyle = {margin: 'auto', height: '30pt'}
const textFieldStyle = {width: '20ch', margin: '5pt', height: '30pt'}

export const defaultFilterState = {
    'building_name': '',
    'city_name': '',
    'country_name': '',
    'status': '',
    'region_name': ''
}


export default function FilterMenu(props: any) {
    const {filterState, setFilterState} = props;
    const {allPrinters, setPrinters} = usePrinterDataContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        if (open) {
            setAnchorEl(null);
        }
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const filter = () => {
        return allPrinters.filter(obj => {
            const matchesFilter = (key: string | number) => {
                const filterValue = filterState[key];
                // Only process if filterValue is not empty and obj[key] is not null/undefined
                // @ts-ignore
                if (filterValue === '' || obj[key] == null) {
                    return filterValue === '';
                }
                // @ts-ignore
                return obj[key].toLowerCase().includes(filterValue.toLowerCase());
            };

            return matchesFilter('building_name') &&
                matchesFilter('city_name') &&
                matchesFilter('country_name') &&
                matchesFilter('region_name') &&
                matchesFilter('status');
        });
    }

    const handleFilterInput = (filterName: string) => (e: any) => {
        e.preventDefault();
        const searchValue: string = e.target.value.toLowerCase();

        let tempFilterState = filterState;
        tempFilterState[filterName] = searchValue;
        setFilterState(tempFilterState);

        const filteredArray = filter();
        setPrinters(filteredArray);
    };


    const handleClearFilter = (filterName: string) => (e: any) => {
        let tempFilterState = filterState;
        tempFilterState[filterName] = '';
        setFilterState(tempFilterState);

        const filteredArray = filter();
        setPrinters(filteredArray);
    };

    const handleClearAllFilters = (e: any) => {
        setPrinters(allPrinters);
        setFilterState({
            'building_name': '',
            'city_name': '',
            'country_name': '',
            'status': '',
            'region_name': ''
        });
    };

    return (
        <React.Fragment>
            <Tooltip title="Filter list">
                <IconButton
                    onClick={handleClickFilter}
                    size="small"
                    sx={{ml: 2}}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <FilterListIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleCloseFilter}
                PaperProps={{
                    sx: {...menuStyle},
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem sx={{display: 'flex'}}>
                    <Button sx={{...buttonStyle}} variant="outlined" onClick={handleClearAllFilters}>Clear</Button>
                    <Button sx={{...buttonStyle}} variant="outlined" onClick={handleCloseFilter}>Close</Button>
                </MenuItem>
                <Divider/>
                <MenuItem onKeyDown={e => e.stopPropagation()}>
                    <TextField
                        label={filterState['building_name'] ? 'Building:' + filterState['building_name'] : 'Building'}
                        value={filterState['building_name'] ? filterState['building_name'] : ''}
                        size="small"
                        sx={{...textFieldStyle}}
                        onChange={handleFilterInput('building_name')}
                    />
                    <IconButton onClick={handleClearFilter('building_name')}>
                        <CloseIcon/>
                    </IconButton>
                </MenuItem>
                <MenuItem onKeyDown={e => e.stopPropagation()}>
                    <TextField
                        label={filterState['city_name'] ? 'City:' + filterState['city_name'] : 'City'}
                        value={filterState['city_name'] ? filterState['city_name'] : ''}
                        size="small"
                        sx={{...textFieldStyle}}
                        onChange={handleFilterInput('city_name')}
                    />
                    <IconButton onClick={handleClearFilter('city_name')}>
                        <CloseIcon/>
                    </IconButton>
                </MenuItem>
                <MenuItem onKeyDown={e => e.stopPropagation()}>
                    <TextField
                        label={filterState['country_name'] ? 'Country:' + filterState['country_name'] : 'Country'}
                        value={filterState['country_name'] ? filterState['country_name'] : ''}
                        size="small"
                        sx={{...textFieldStyle}}
                        onChange={handleFilterInput('country_name')}
                    />
                    <IconButton onClick={handleClearFilter('country_name')}>
                        <CloseIcon/>
                    </IconButton>
                </MenuItem>
                <MenuItem onKeyDown={e => e.stopPropagation()}>
                    <TextField
                        label={filterState['status'] ? 'Status:' + filterState['status'] : 'Status'}
                        value={filterState['status'] ? filterState['status'] : ''}
                        size="small"
                        sx={{...textFieldStyle}}
                        onChange={handleFilterInput('status')}
                    />
                    <IconButton onClick={handleClearFilter('status')}>
                        <CloseIcon/>
                    </IconButton>
                </MenuItem>
                <MenuItem onKeyDown={e => e.stopPropagation()}>
                    <TextField
                        label={filterState['region_name'] ? 'Region:' + filterState['region_name'] : 'Region'}
                        value={filterState['region_name'] ? filterState['region_name'] : ''}
                        size="small"
                        sx={{...textFieldStyle}}
                        onChange={handleFilterInput('region_name')}
                    />
                    <IconButton onClick={handleClearFilter('region_name')}>
                        <CloseIcon/>
                    </IconButton>
                </MenuItem>

            </Menu>
        </React.Fragment>
    );
}
