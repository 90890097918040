import * as React from 'react';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {FileDrop} from "./DropZone";
import {Box, CircularProgress, Fade, Menu, MenuItem} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from "axios";
import StickyHeadTable from "./ResultTable";
import config from "../../config";
import commonStyles from "../../styles/commonStyles.module.css";


const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const MyDropdown = (props: {
    operations: { [key: string]: string };
    setOperation: any
}) => {
    const operations = props.operations;
    const setOperation = props.setOperation;

    const [selectedValue, setSelectedValue] = React.useState<String>(Object.keys(operations)[0]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setOperation(Object.values(operations)[0]);
    };
    const handleOption = (event: any) => {
        setSelectedValue(event.target.textContent);
        setOperation(operations[event.target.textContent]);
        setAnchorEl(null);
    };

    return (
        <div style={{margin: 'auto 0'}}>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                endIcon={<KeyboardArrowDownIcon/>}
                onClick={handleClick}
            >
                {selectedValue}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
            >
                {Object.keys(operations).map((key) => (
                    <MenuItem onClick={handleOption}>{key}</MenuItem>
                ))}
            </Menu>
        </div>
    )
};


export default function CustomizedDialogs(props:
{
    name: string;
    url: string;
    operation?: { [key: string]: string };
    exampleFile: string;
    exampleFileType: string;
}) {
    const operationName = props.name;
    const api_url = config.apiUrl + 'eop_hcp' + props.url;
    const operationsMapper = props.operation;
    const exampleFile = props.exampleFile;
    const exampleFileType = props.exampleFileType;

    const [open, setOpen] = React.useState(false);
    const [fileError, setFileError] = React.useState('');
    const [file, setFile] = React.useState<File | null>(null);
    const [fileData, setFileData] = React.useState<any | null>(null);
    const [operation, setOperation] = React.useState<String | null>(
        operationsMapper ? Object.values(operationsMapper)[0] : null);
    const [requestIsLoading, setRequestIsLoading] = React.useState(false);
    const [apiResponse, setApiResponse] = React.useState<Object | null>(null);

    let token = localStorage.getItem('token')

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setApiResponse(null);

        setFile(null);
        setFileData(null);
    };

    const handleRemoveFile = () => {
        setFile(null);
        setFileData(null);
    };

    const handleRemoveError = () => {
        setFileError('');
    };

    const dataFromJSON = async (file: File) => {
        const fileUrl = URL.createObjectURL(file);
        const response = await fetch(fileUrl);

        return await response.json();
    };

    const dataFromCSV = async (file: File) => {
        // @ts-ignore
        const fileUrl = URL.createObjectURL(file);
        const response = await fetch(fileUrl);

        const text = await response.text();
        const lines = text.split("\n");

        const _data = lines.map((line) => line.split(","));
        return transformArrayToObject(_data)
    };

    const validateCsvColumns  = (requiredColumns: Array<string>, data: Object) => {
        return requiredColumns.filter(key => !(key in data));
    }

    const validateSerialInName = (data: Array<any>) => {
        return data.every(obj => obj['Name'].includes(obj['Serial Number']));
    }

    const validateName = (data: Array<any>) => {
        return data.every(
            obj => /^[a-zA-Z0-9-]{2,15}_([a-zA-Z0-9]{10,11})--([0-9]{1,3}\.){3}[0-9]{1,3}(__.*)?$/.test(obj['Name'])
        );
    }

    const validateEmbeddedClientFile = (data: any) => {
        const embeddedColumns = ['Name', 'Serial Number'];
        let missingColumns = validateCsvColumns(embeddedColumns, data[0]);

        if (missingColumns.length > 0){
            let error = `Error! No columns '${missingColumns.join("', '")}' in the file.
            \nRequired columns: '${embeddedColumns.join("', '")}'.`;
            setFileError(error);
            return error;
        }

        let serialInName = validateSerialInName(data)
        if (!serialInName) {
            let error = "Error! Printer 'Name' doesn't include 'Serial Number'";
            setFileError(error);
            return error;
        }

        return '';
    };

    const validateOutputPortFile = (data: any) => {
        const outputPortColumns = ['Name', 'Building Code', 'Region', 'Serial Number', 'IP Address'];
        let missingColumns = validateCsvColumns(outputPortColumns, data[0]);

        if (missingColumns.length > 0){
            let error = `No columns: '${missingColumns.join(", '")}' in the file.
            \nRequired columns: '${outputPortColumns.join("', '")}'.`;
            setFileError(error);
            return error;
        }

        let structureName = validateName(data)
        if (!structureName) {
            let error = "Error! Wrong printers naming";
            setFileError(error);
            return error;
        }

        return '';
    };

    const fileValidation = {
        "Embedded Client setup": validateEmbeddedClientFile,
        "Printer setup": validateOutputPortFile,
    }

    const onFileSet = async (file: File) => {
        let localValidationStatus = '';
        let data = {
            'name': file.name,
            'type': file.type,
            'fileData': Object
        }

        if ( exampleFileType == 'CSV') {
            data['fileData'] = await dataFromCSV(file);
            // @ts-ignore
            localValidationStatus = fileValidation[operationName](data['fileData']);
        }
        else if ( exampleFileType == 'JSON') {
            data['fileData'] = await dataFromJSON(file);
        }

        if (!localValidationStatus){
            setFileData(data);
            setFile(file);
            setFileError('');
        }
    };

    const editPrinterResponse = async (res: any) => {
        const data = [...fileData['fileData']];

        // @ts-ignore
        data.forEach(({ Name, 'Serial Number': serialNumber}) => {
            if (res["eop_response"][serialNumber]) {
                res["eop_response"][serialNumber]["name"] = Name;
          }
        });

        setApiResponse(res);
    };

    const editEmbeddedClientResponse = async (res: any) => {
        const data = [...fileData['fileData']];

        const responseToShow: any = [];

        // @ts-ignore
        data.forEach(({ Name, 'Serial Number': serialNumber}) => {
                if (res['serials_unknown'].includes(serialNumber)){
                    responseToShow.push({'name': Name, 'status': 'serial unknown'})
                }else if (res["serials_not_executed"].includes(serialNumber)) {
                    responseToShow.push({'name': Name, 'status': 'failed'})
                }else{
                    responseToShow.push({'name': Name, 'status': 'success'})
                }
            });

        setApiResponse({'embedded_clients': responseToShow});
    };

    const transformArrayToObject = (inputArray: any) => {
        // Extracting keys from the first sub-array
        const [keys, ...values] = inputArray;

        // Creating objects with 'name' and 'value' keys
        const resultArray = values.map((valueArray: any) => {
            const obj = {};
            keys.forEach((key: any, index: any) => {
                // @ts-ignore
                obj[key] = valueArray[index];
            });
            return obj;
        });

        return resultArray;
    };

    const handleSubmitEvent = (e: any) => {
        const formData = new FormData();

        if(operation != null){
            // @ts-ignore
            formData.append('operation', operation);
        }

        if (file != null){
            formData.append('file', file!);
        }

        setRequestIsLoading(true);

        if (operationName == 'Containers setup') {
            axios.put(api_url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(response => {
                    setRequestIsLoading(false);
                    setApiResponse(response.data);
                })
                .catch(error => {
                    console.error('Error uploading file', error);
                    setRequestIsLoading(false);
                });
        }

        else{
            axios.post(api_url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(response => {
                    setRequestIsLoading(false);
                    if (operationName == 'Printer setup'){
                        editPrinterResponse(response.data);
                    }
                    else {
                        editEmbeddedClientResponse(response.data);
                    }
                })
                .catch(error => {
                    console.error('Error uploading file', error);
                    setRequestIsLoading(false);
                });
        }
    }

    // @ts-ignore
    return (
        <React.Fragment>
            <Button
                sx={{margin: '5pt', height: '30pt'}}
                variant="outlined"
                onClick={handleClickOpen}
            >
                {operationName}
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    {operationName}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                {apiResponse != null ?
                    <Box style={{minHeight: '160pt', maxHeight: '260pt', width: '400pt'}}>
                        <StickyHeadTable dataType={operationName} tableData={apiResponse}/>
                    </Box>:
                <DialogContent dividers>
                    <Box style={{minHeight: '160pt', maxHeight: '260pt', width: '400pt'}}>
                    {typeof operationsMapper == "undefined" ? null :
                        <Box style={{display: 'flex'}}>
                            <p>Choose the operation:</p>
                            <MyDropdown operations={operationsMapper!} setOperation={setOperation}/>
                        </Box>
                    }
                    <Box style={{height: '150pt', width: '400pt'}}>
                        <FileDrop setFile={onFileSet}/>
                    </Box>
                    {!fileError ?
                        fileData == null ? null :
                        <Box style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '2%',
                            width: '100%',
                            borderRadius: '3pt',
                            backgroundColor: '#eeeeee'
                        }}
                        >
                            <p style={{paddingLeft: '8px', float: 'left'}}> {fileData.name} </p>
                            <IconButton
                                aria-label="close"
                                onClick={handleRemoveFile}
                                sx={{
                                    float: 'right',
                                    margin: 'auto 0',
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box> :
                         <Box style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '2%',
                            width: '100%',
                            borderRadius: '3pt',
                            backgroundColor: '#eeeeee'
                        }}
                        >
                             <p style={{paddingLeft: '8px', float: 'left', color: 'red'}}>
                                 {fileError.split('\n').map(
                                     (line, index) =>
                                         <React.Fragment key={index}>{line}<br/></React.Fragment>
                                 )}
                             </p>
                            <IconButton
                                aria-label="close"
                                onClick={handleRemoveError}
                                sx={{
                                    float: 'right',
                                    margin: 'auto 0',
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    }

                {requestIsLoading ?
                    <Box style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgb(240 240 240 / 90%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}>
                        <CircularProgress/>
                        <p style={{color: '#1976d2'}}>It may take up to a minute ... </p>
                    </Box>
                    : null }
                    </Box>
                </DialogContent>}
                <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <p>Download a
                        <a href={window.location.origin + '/file_examples/' + exampleFile}
                           style={{textDecoration: 'None', color: '#1976d2'}} download> {exampleFileType} template</a>
                    </p>
                    <Button component="label" variant="contained"
                            onClick={handleSubmitEvent}
                            disabled={Boolean(fileError) || requestIsLoading || Boolean(apiResponse)}>
                        Submit
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}