import axios from 'axios';
import config from "../config";
import {UserPublicDataType} from "../types/authTypes";
import {Action, printerDataType} from "../types/printerTypes";

class ApiInstanceClass {
    axiosInstance = axios.create({
        baseURL: config.apiUrl
    });

  createAxiosInstance(token: string) {
    this.axiosInstance = axios.create({
      withCredentials: true,
      baseURL: config.apiUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getAxiosInstance() {
    return this.axiosInstance;
  }

  getUserInfo() {
    return ApiInstance.getAxiosInstance()
      .get<UserPublicDataType>('user/info')
      .then((r) => r.data);
  }

  getPrinters() {
    return ApiInstance.getAxiosInstance()
      .get('eop_hcp/outputports')
      .then((r) => r.data)
      .catch(function (error) {
          // Added logic for sending notifications
          return [];
      });
  }

  getPrintersHistory(printers_data: printerDataType[]) {
    return ApiInstance.getAxiosInstance()
        .post('eop_hcp/outputports/history', {
          portnames: printers_data.map(printer => printer.name),
        })
        .then((r) => r.data)
        .catch(function (error) {
          // Added logic for sending notifications
          return [];
        });
  }

  embeddedClient(
      printersData: printerDataType[],
      operation: Action | null,
      updateEmbeddedClient: boolean,
      reader: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post('eop_hcp/embedded_client', {
          printers_data: printersData,
          operation: operation,
          update_embedded_client: updateEmbeddedClient,
          embedded_client: reader,
      })
      .then((r) => r.data)
      .catch(function (error) {
        // Added logic for sending notifications
        return [];
    });
  }

  createOutputPort(
    ip: string | null,
    serial: string | null,
    detail: string | null,
    building: string | null,
  ){
      return ApiInstance.getAxiosInstance()
      .post('eop_hcp/outputport', {
          ip: ip,
          serial: serial,
          detail: detail,
          building: building,
      })
      .then((r) => r.data)
      .catch(function (error) {
        // Added logic for sending notifications
        return [];
    });
  }

  updateOutputPort(
    id: number | null,
    ip: string | null,
    serial: string | null,
    detail: string | null,
    building: string | null,
    updateContainer: boolean,
  ){
      return ApiInstance.getAxiosInstance()
      .put('eop_hcp/outputport', {
          id: id,
          ip: ip,
          serial: serial,
          detail: detail,
          building: building,
          update_container: updateContainer,
      })
      .then((r) => r.data)
      .catch(function (error) {
        return [];
    });
  }
}

export const ApiInstance = new ApiInstanceClass();
