import {useEffect, useState, Fragment} from "react";
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Alert, Box, Fade, Menu, MenuItem, Snackbar} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import TextField from '@mui/material/TextField';
import {usePrinterDataContext} from "../../../context/printerDataContext";
import CopyToClipboardButton from "../CopyToClipboardButton";

import s from "./PrinterForm.module.css"
import {utils_instance} from "../../../utils/Utils";
import {ApiInstance} from "../../../api/api";
import {outputPortCreationResponseDataType} from "../../../types/printerTypes";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";


const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
}));


const MyDropdown = (props: {
    set_option: string | null;
    options: Array<string | null>;
    setFilter: any;
    disabled: Boolean;
}) => {
    const set_option = props.set_option;
    const options = props.options;
    const setFilter = props.setFilter;
    const disabled = props.disabled;

    const [selectedValue, setSelectedValue] = useState<string | null>(
        set_option != null ? set_option : options[0]
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleOption = (event: any) => {
        setSelectedValue(event.target.textContent);
        setFilter(event.target.textContent)
        setAnchorEl(null);
    };

    useEffect(() =>{
        if(set_option == null){
            setSelectedValue('chose one')
        }
    }, [set_option])


    return (
        <Fragment>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                endIcon={<KeyboardArrowDownIcon/>}
                onClick={handleClick}
                disabled={disabled ? true : false}
            >
                {selectedValue}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{'aria-labelledby': 'fade-button'}}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
            >
                {options.map(key2 => <MenuItem onClick={handleOption}>{key2}</MenuItem>)}
            </Menu>
        </Fragment>
    )
};

export default function CustomizedDialogs(props:
{
    name: string;
    url: string;
}) {
    const {allPrinters} = usePrinterDataContext();
    const operationName = props.name;

    const [open, setOpen] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [apiResponse, setApiResponse] = useState<outputPortCreationResponseDataType | null>(null);

    const [region, setRegion] = useState<string | null>(null)
    const [country, setCountry] = useState<string | null>(null)
    const [city, setCity] = useState<string | null>(null)
    const [building, setBuilding] = useState<string | null>(null)

    const [countries, setCountries] = useState<Array<string | null>>(['chose one'])
    const [cities, setCities] = useState<Array<string | null>>(['chose one'])
    const [buildings, setBuildings] = useState<Array<string | null>>(['chose one'])

    const [ipAddress, setIpAddress] = useState<string | null>(null);
    const [detail, setDetail] = useState<string | null>(null);
    const [serial, setSerial] = useState<string | null>(null);
    const [printerName, setPrinterName] = useState<string>('BUILD_SERIAL--IP__DETAIL');

    const [ipError, setIpError] = useState(false);
    const [error, setError] = useState(true);

    const uniqueRegions = Array.from(
      new Set(allPrinters.map(item => item.region_name))
    ).filter(name => name !== 'nan');

    useEffect(() =>{
        if (region){
            const uniqueCountries = Array.from(
              new Set(allPrinters
                  .filter(item => item.region_name === region)
                  .map(item => item.country_name))
            ).filter(name => name !== 'nan');

            setCountries(uniqueCountries)
            setCountry(null)
            setCity(null)
            setBuilding(null)
        }

    }, [region])

    useEffect(() =>{
        if (country){
            const uniqueCities = Array.from(
              new Set(allPrinters
                  .filter(item => item.region_name === region)
                  .filter(item => item.country_name === country)
                  .map(item => item.city_name))
            ).filter(name => name !== 'nan');

            setCities(uniqueCities)
            setCity(null)
            setBuilding(null)
        }

    }, [country])

    useEffect(() =>{
        if (city){
            const uniqueBuildings = Array.from(
              new Set(allPrinters
                  .filter(item => item.region_name === region)
                  .filter(item => item.country_name === country)
                  .filter(item => item.city_name === city)
                  .map(item => item.building_name))
            ).filter(name => name !== 'nan');

            setBuildings(uniqueBuildings)
            setBuilding(null)
        }
    }, [city])

    // Printer name creation and params validation
    useEffect(() =>{
        let b_name =  building ? building : 'BUILD'
        let b_serial =  serial ? serial : 'SERIAL'
        let b_ip =  ipAddress ? ipAddress : 'IP'
        let b_detail =  detail ? detail : 'DETAIL'

        let name = b_name + '_' + b_serial + '--' + b_ip + '__' + b_detail
        setPrinterName(name);

        setError(false)
        if (building == null){setError(true)}
        if (ipAddress == null || !utils_instance.isIPAddressValid(ipAddress)){setError(true)}
        if (serial == null || serial === ''){setError(true)}
        if (detail == null || detail === ''){setError(true)}

    }, [building, ipAddress, serial, detail])

    useEffect(() =>{
        if (apiResponse != null && apiResponse["status"]){
            setPrinterName(apiResponse["printer_name"])
        }
    }, [apiResponse])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setApiResponse(null);

        setIpAddress(null);
        setDetail(null);
        setSerial(null);

        setRegion(null);
        setCountry(null);
        setCity(null);
        setBuilding(null);
    };

    const handleSubmitEvent = async (e: any) => {
        const res = await ApiInstance.createOutputPort(ipAddress, serial, detail, building);

        if (res['status']){
            let created_printer = printerName;

            setIpAddress(null);
            setDetail(null);
            setSerial(null);

            setPrinterName(created_printer);
        }
        setApiResponse(res);
        setOpenMessage(true);
    }

    // @ts-ignore
    return (
        <Fragment>
            <Button
                sx={{margin: '5pt', height: '30pt'}}
                variant="outlined"
                onClick={handleClickOpen}
            >
                {operationName}
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    {operationName}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Box className={s.form_item}>
                        <Box className={s.box_item}>
                            <p>Choose the Region:</p>
                            <MyDropdown options={uniqueRegions}
                                        setFilter={setRegion}
                                        set_option={region}
                                        disabled={false}/>
                        </Box>
                        <Box className={s.box_item}>
                            <p>Choose the Country:</p>
                            <MyDropdown options={countries}
                                        setFilter={setCountry}
                                        set_option={country}
                                        disabled={region == null}/>
                        </Box>
                        <Box className={s.box_item}>
                            <p>Choose the City:</p>
                            <MyDropdown options={cities}
                                        setFilter={setCity}
                                        set_option={city}
                                        disabled={country == null}/>
                        </Box>
                        <Box className={s.box_item}>
                            <p>Choose the Building:</p>
                            <MyDropdown options={buildings}
                                        setFilter={setBuilding}
                                        set_option={building}
                                        disabled={city == null}/>
                        </Box>
                        <Box className={s.box_item}>
                            <p>Serial:</p>
                            <TextField
                                id="outlined-basic"
                                placeholder="Serial"
                                value={serial ? serial : ''}
                                variant="outlined" size="small"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => setSerial(e.target.value)} />
                        </Box>
                        <Box className={s.box_item}>
                            <p>IP Address:</p>
                            <TextField
                                id="outlined-basic"
                                placeholder="IP Address"
                                value={ipAddress ? ipAddress : ''}
                                variant="outlined" size="small"
                                error={ipError}
                                onChange={(e) => {
                                    setIpAddress(e.target.value);
                                    setIpError(!utils_instance.isIPAddressValid(e.target.value));
                                }}/>
                        </Box>
                        <Box className={s.box_item}>
                            <p>Detail:</p>
                             <TextField
                                 id="outlined-basic"
                                 placeholder="Detail"
                                 value={detail ? detail : ''}
                                 variant="outlined" size="small"
                                 onChange={(e) => setDetail(e.target.value)} />
                        </Box>
                        <Box className={s.box_device_name}>
                            {printerName}
                            <CopyToClipboardButton text={printerName}/>
                        </Box>
                        {apiResponse == null ? null :
                            apiResponse['status'] ?
                                <Fragment>
                                    <Snackbar
                                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                        autoHideDuration={10000}
                                        onClose={() => setOpenMessage(false)}
                                        open={openMessage}
                                    >
                                        <Alert icon={false} severity="success" id={s.success_alert_popup}>
                                            <div className={s.alert_content}>
                                                <div className={s.alert_icon_section}>
                                                    <CheckCircleOutlineIcon fontSize="large" className={s.alert_icon} />
                                                </div>
                                                <div>
                                                    <Typography>Printer created successfully! To see the changes, please reload the page.</Typography>
                                                    <Typography variant="body2">Please make sure that you <b>Install & Configure</b> printer before using it!</Typography>
                                                </div>
                                            </div>
                                        </Alert>
                                    </Snackbar>
                                </Fragment>
                                :
                                <Fragment>
                                    <Alert variant="outlined" severity="error">
                                      Error occurred
                                    </Alert>
                                    <Snackbar
                                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                        autoHideDuration={10000}
                                        onClose={() => setOpenMessage(false)}
                                        open={openMessage}
                                    >
                                        <Alert icon={false} severity="error" id={s.error_alert_popup}>
                                            <div className={s.alert_content}>
                                                <div className={s.alert_icon_section}>
                                                    <ErrorOutlineIcon fontSize="large" className={s.alert_icon} />
                                                </div>
                                                <div>
                                                    <Typography>Printer not created!</Typography>
                                                    <Typography variant="body2">Error occurred - please try again!</Typography>
                                                </div>
                                            </div>
                                        </Alert>
                                    </Snackbar>
                                </Fragment>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button component="label" variant="contained"
                            onClick={handleSubmitEvent}
                            disabled={error}>
                        Submit
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    );
}